"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import React from "react";
import { WagmiProvider } from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";

const queryClient = new QueryClient();

const projectId = "23c758281808a5201023705526688299";

const metadata = {
  name: "Token Metrics",
  description: "Connect your wallet to Token Metrics",
  url: "https://app.tokenmetrics.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [mainnet, sepolia] as const;
const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

createWeb3Modal({
  metadata,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
});

export type WagmiConfigProps = React.PropsWithChildren<{}>;

const WagmiConfigWrapper: React.FC<WagmiConfigProps> = ({ children }) => {
  return <WagmiProvider config={config}>{children}</WagmiProvider>;
};

// Explicitly define the children prop type in the App component
const App: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfigWrapper>{children}</WagmiConfigWrapper>
    </QueryClientProvider>
  );
};

export default App;
